import Http from "@/services/Http";
export default {
  getExams(data) {
    return Http().post("exam/get", data);
  },
  getQuestions(data) {
    return Http().post("exam/questions", data);
  },
  submitExam(data) {
    return Http().post("exam/submit", data);
  },
  getExamResults() {
    return Http().post("report/exam");
  },
  getExamResult(data) {
    return Http().post("exam/get/result", data);
  },
  getTodayExams(data) {
    return Http().post("exam/today", data);
  }
};
